.textHead {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.arrow {
  @apply w-4 h-4 ml-2;
}

.headContainer {
  @apply h-full flex px-2 border-r border-gray-300;
}

.headContainer:last-child {
  @apply border-r-0;
}

.arrowFilter {
  transform: rotate(0) !important;
}
