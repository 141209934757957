.description {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  display: flex;
  flex-direction: column;
  @apply h-109px p-2 lg:h-161px lg:p-3 relative;
}

.descriptionForMap {
  display: flex;
  flex-direction: column;
  @apply h-120px px-2 py-1 relative;
}

.descriptionInner {
  min-height: 32px;
  @apply grid-cols-2 grid gap-1 my-2;
}

.descriptionInnerForMap {
  min-height: 32px;
  @apply grid-cols-2 grid gap-1 my-2;
}

.postTitleRealEstate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  min-height: 24px;
  @apply uppercase text-11 w-full font-text lg:text-18 font-bold;
}
.postTitleRealEstateForMap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  min-height: 22px;
  @apply uppercase text-11 w-full font-bold;
}

.priceText {
  color: #ec0909;
  font-size: 15px;
}

.priceTextForMap {
  color: #ec0909;
  font-size: 15px;
}

.txtQuickInfoForMap {
  font-size: 10px !important;
  @apply truncate text-gray-600;
}

.iconQuickInfoForMap {
  width: 10px !important;
  height: 10px !important;
  @apply mr-1;
}

.unitPrice {
  color: #ec0909;
  font-size: 10px;
  @apply truncate font-text font-bold;
}

.unitPriceForMap {
  color: #ec0909;
  font-size: 10px;
  @apply truncate;
}

.createdAtText {
  font-size: 10px;
  font-style: italic;
  @apply text-gray-600;
}
.priceContainer {
  @apply flex flex-1 flex-row w-full justify-between items-center;
}

@media (min-width: 1024px) {
  .postTitleRealEstate {
    min-height: 52px;
    line-height: 26px !important;
  }
  .unitPrice {
    font-size: 14px;
  }
  .priceText {
    font-size: 22px;
  }
  .descriptionInner {
    min-height: 48px;
  }
}
