

.changeAvatarContainer{
  @apply w-160px h-160px relative rounded-full bg-cover bg-no-repeat bg-center overflow-hidden
}

.camera{
  background-color: #22222250;
  @apply absolute bottom-0 w-full h-40px justify-center items-center flex hidden
}
.changeAvatarContainer:hover .camera{
  display: block;
  background-color: #22222250;
  @apply absolute bottom-0 w-full h-40px justify-center items-center flex
}
