.modal {
  @apply flex items-center justify-center;
}

.modalInner {
  @apply bg-white max-w-95% max-h-95% flex flex-col relative rounded-lg;
}


.closeButton{
  z-index: 1000;
  @apply absolute right-4 top-4 cursor-pointer
}
