

.container{
  border-radius: 9px;
  @apply w-full flex flex-1 flex-row border border-dashed border-blue-main overflow-hidden
}

.leftButtonContainer{
  @apply flex flex-col w-138px
}
.leftButton{
  @apply flex-1 flex flex-col items-center justify-center space-y-1 p-3
}

.rightContainer{
  min-height: 241px;
  @apply  flex-1 border-t-0 border-l border-blue-main border-dashed
}

.descriptionYoutube{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 10px;
  @apply  text-black-main
}
