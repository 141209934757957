.viewContainer {
  & .react-viewer-list > li {
    width: 60px;
  }
  & .react-viewer-mask {
    z-index: 1300 !important;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1;
  }
  & .react-viewer-close {
    position: fixed;
    top: 0px;
    right: 0px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    border-radius: 0 0 0 40px;
    cursor: pointer;
    z-index: 1310 !important;
  }
  & .react-viewer-canvas {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1305 !important;
  }
  & .react-viewer-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    text-align: center;
    z-index: 1305 !important;
  }
  & .react-viewer-toolbar > li {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    margin-right: 3px;
    cursor: pointer;
    line-height: 35px;
  }
  & .react-viewer-toolbar {
    overflow: hidden;
    height: 35px;
    margin-bottom: 6px !important;
  }
}
