.radioButton {
  border: 2px solid #dedede;
  border-radius: 50%;
  padding: 2px;
  width: 18px;
  height: 18px;
  background-color: white;
  @apply mr-1;
}

.checked {
  border-color: #2459ad;
}

.innerChecked {
  @apply w-full h-full rounded-full;
}
