

.label{
  @apply text-blue-main font-semibold text-base
}
.inputContainer {
  border-color: #2459ad;
  height: 100%;
  @apply rounded-lg border border-solid relative
}
.innerInput{
  outline:none!important;
  resize: none;
  @apply flex-1 text-sm rounded-lg placeholder-gray-400
}
.inputError {
  @apply border-red-400;
}

