

.bottomMinMaxContainer{
  @apply flex-1 flex flex-row items-center justify-between pl-1.5;
}
.labelContainer{
  position: absolute;
  top: -20px;
  left: -27px;
  height: 20px;
  min-width: 72px;
}
.getAllContainer{
  min-width: 72px;
  @apply  text-black-main font-semibold text-11
}
.labelLeft{
  width: 100px;
  align-items: center;
  justify-content: center;
}
.labelRightLong{
  width: 100px;
  align-items: flex-start;
  margin-left: -28px
}
.labelRightShort{
   width: 100px;
   align-items: flex-start;
   margin-left: -12px
 }
