.itemTable {
  &:last-child {
    @apply border-r-0;
  }
  @apply h-40px flex flex-row text-sm border-r border-gray-300 px-2 items-center text-black-main;
}
.row {
  @apply flex flex-row flex-1;
}
.row:hover {
  background-color: #ffe6b450;
}
.tagFilterResult {
  background-color: #f4f4f4;
  @apply w-auto flex flex-row items-center justify-between overflow-hidden space-x-1.5 py-1 px-2 rounded-rd16px max-w-100% h-23px;
}
