.container {
  @apply bg-white rounded p-3 border border-blue-main flex flex-col
}

.itemDate {
  @apply w-6 h-6 flex flex-col items-center justify-center rounded-full text-13
}

.calendarContainer {
  @apply flex flex-col border-dashed border border-blue-main rounded p-2
}

.today {
  @apply bg-red-600 text-white
}


.boxTime {
  width: 40px;
  height: 40px;
  @apply bg-gray-200 rounded flex items-center justify-center text-sm font-bold
}

.dateSelected {
  @apply bg-blue-main text-white
}

.buttonChangeMonth {
  @apply w-6 h-6 mr-1 rounded-full border border-blue-main flex items-center justify-center
}
