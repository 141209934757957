
.radioWidthInput{
 width: 62px !important;
 height: 40px;
 outline:none!important;
 border-radius: 7px;
 border-color: #2459ad;
 @apply text-13 rounded-lg placeholder-gray-400 text-center border
}

.radioWidthInputActive{
 border-color: transparent !important;
 @apply text-13 text-white font-semibold bg-blue-main
}
