.container {
  @apply h-full
}

.containerFullScreen {
  height: 100vh;
}

.line {
  border-top: 1px solid  #2459ad;
  margin: 0 4px;
}


.header {
  display: none !important;
}

@media (min-width: theme('screens.sm')) {
  .header {
    display: flex !important;
  }
}

.buttonMapStyle {
  @apply w-32px h-32px rounded-rd4px border border-gray-300 bg-white flex flex-col
}
.centering {
  @apply justify-center items-center
}

.buttonZoom {
  border: 0;
  width: 31px !important;
}

.containerButtonZoom {
  @apply rounded border border-gray-300 flex flex-col
}

.activeButton {
  @apply border border-blue-main
}

.poUpMeasuring {
  z-index: 800;
  @apply flex flex-col relative mx-4 my-16
}

@media (min-width: theme('screens.sm')) {
  .poUpMeasuring {
    top: 120px;
    left: 16px;
    @apply absolute items-center m-0
  }
}

.tutorial {
  @apply bg-white py-1 px-2 flex flex-col justify-center
}

.inputSearch {
  border-radius: 0 8px 8px 0;
  @apply h-40px flex-1 flex flex-row items-center border-l-0 overflow-hidden
}

.inputSearchMobile {
  border-radius: 8px;
  @apply h-40px flex-1 border border-blue-main flex flex-row items-center bg-white overflow-hidden
}

.inputSearchInternal {
  border-radius: 8px;
  @apply border-l border-blue-main
}

.input {
  outline:none!important;
  border:none !important;
  @apply text-13 font-text rounded-rd8px px-2 w-full h-full overflow-hidden
}

.input:disabled {
  background-color: #FAFAFA;
}

.wrapperInput {
  border-radius: 0 8px 8px 0;
  @apply border border-blue-main border-l-0 h-40px bg-white overflow-hidden flex items-center
}

.wrapper {
  @apply w-full border border-blue-main rounded-lg bg-white
}

.itemSuggest {
  @apply w-full flex border-b border-gray-300 items-center space-x-0.5 truncate flex-row h-32px
}

.itemSuggest:last-child {
  @apply border-b-0
}

.demandSelector {
  border-radius: 8px 0 0 8px;
  @apply flex border border-blue-main w-135px overflow-hidden h-40px
}


.filterMore {
  @apply flex flex-row items-center rounded-lg border border-blue-main h-40px px-2 space-x-1.5 bg-white w-116px ml-4
}

.topItemLoading {
  height: 101px !important;
  @apply flex w-full relative
}

.bottomItemLoading {
  height: 90px !important;
  @apply p-2
}

.closeButton{
  fill: #222222;
  z-index: 20000;
  @apply absolute right-2 top-2 cursor-pointer
}

.closeButton2{
  fill: #222222;
  @apply absolute right-1 top-1 cursor-pointer z-10
}

.itemLandType {
  border-radius: 12px;
  @apply w-full flex flex-row p-4
}

.itemWorldHighWay {
  @apply flex flex-row h-40px w-full border-t border-gray-300 px-1
}

.popup {
  z-index: 800;
  left: 16px;
  bottom: 24px;
  height: calc(100vh - 180px);
  @apply w-438px absolute overflow-hidden flex flex-col py-4
}

.drawerPopup {
  max-height: 400px;
  @apply flex flex-col w-full py-4 overflow-y-auto border border-white rounded-lg
}

.containerHeadMap {
  @apply flex flex-row w-full items-start justify-between px-6
}

.containerSearch {
  max-width: 1000px;
  z-index: 900;
  top: 80px;
  @apply flex-1 p-1 mr-4 rounded-lg flex flex-col fixed left-6
}

@media (min-width: 1024px) {
  .containerSearch {
    width: 950px;
  }
}
