.pagination {
  @apply flex items-center gap-x-2;
}
.paginationInfo {
  @apply text-black-main text-13;
}
.paginationItem {
  border-color: #b4b8bc;
  box-shadow: 0px 1px 1px rgba(36, 89, 173, 0.25);
  @apply flex justify-center items-center min-w-32px h-32px rounded-rd8px border cursor-pointer;
  &.prev,
  &.next {
    @apply rounded-rd4px;
  }
  &.active {
    @apply text-blue-main border-blue-main bg-blue-light cursor-default;
  }
  &.disabled {
    @apply opacity-30 pointer-events-none;
  }
  &.break {
    @apply pointer-events-none;
  }
  & > span {
    @apply text-13 font-bold text-black-main;
  }
}
.paginationInput {
  border-color: #b4b8bc;
  box-shadow: 0px 1px 1px rgba(36, 89, 173, 0.25);
  @apply flex justify-center items-center w-132px h-32px rounded-rd8px border text-13 py-3 px-5;
  &:focus {
    box-shadow: 0px 0px 5px #79adff;
    @apply border-blue-main outline-none;
  }
}
