.realEstate {
  width: 100%;
  border-radius: 15px;
  background-color: #ffffff;
  border: 1px solid #80888f;
  -webkit-transition: -webkit-transform 0.5s ease;
  -moz-transition: -moz-transform 0.5s ease;
  transition: transform 0.5s ease;
  @apply overflow-hidden;
}

.realEstateForMap {
  border-radius: 15px;
  border: 1px solid #80888f;
  -webkit-transition: -webkit-transform 0.5s ease;
  -moz-transition: -moz-transform 0.5s ease;
  @apply bg-white w-full overflow-hidden flex flex-col overflow-hidden;
}

.realEstateForMap:hover {
  transform: scale(1.05);
  transition-duration: 0.5s;
  border-color: #2459ad;
  box-shadow: -1px 0 8px rgba(36, 89, 173, 0.73);
}

.realEstate:hover * .realEstateId {
  transform: translateY(0);
  opacity: 1;
}

.realEstate:hover *.mediaCountContainer {
  transform: translateY(0);
  opacity: 1;
}

.iconQuickInfo {
  width: 10px;
  height: 10px;
  @apply mr-1;
}

.txtQuickInfo {
  font-size: 10px;
  @apply truncate text-gray-600;
}

@media (min-width: 1024px) {
  .iconQuickInfo {
    width: 16px;
    height: 16px;
  }
  .txtQuickInfo {
    font-size: 14.76px;
  }
}

.iconQuickInfo > * {
  color: #80888f !important;
}

.mediaCountContainer {
  right: 8px;
  bottom: 7px;
  transition: all 0.5s ease;
  @apply flex-col flex w-full items-end justify-end flex-1 absolute space-y-1;
}

.mediaCountContainerForMap {
  right: 8px;
  bottom: 7px;
  @apply flex-col flex w-full items-end justify-end flex-1 absolute space-y-1;
}

.imageRealEstate {
  width: 100%;
  position: relative;
  @apply flex h-101px lg:h-148px;
}

.imageRealEstateForMap {
  @apply flex h-101px w-full relative;
}

.image {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  @apply w-full h-full bg-no-repeat bg-center bg-cover;
}
.countMediaFile {
  background-color: #0000004d;
  border-radius: 5px;
  margin-right: 4px;
  width: 35.07px;
  height: 18px;
  max-width: 50px;
  @apply flex-row items-center flex justify-center;
}

.countMediaFileForMap {
  background-color: #0000004d;
  border-radius: 5px;
  margin-right: 4px;
  width: 35.07px;
  height: 18px;
  max-width: 50px;
  @apply flex-row items-center flex justify-center;
}

.textCountMedia {
  color: #fff;
  font-size: 6.97px;
  margin-left: 2px;
  @apply font-text;
}

.textCountMediaForMap {
  font-size: 6.97px;
  margin-left: 2px;
  @apply font-text text-white;
}

.contentDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.5s ease;
  margin-left: 4px;
}
.realEstateId {
  background-color: #0000004d;
  border-radius: 3px;
  left: 8px;
  bottom: 7px;
  max-width: 150px;
  transition: all 0.5s ease;
  @apply flex-row flex items-center h-18px lg:h-29px p-1 absolute space-x-1;
}
.realEstateIdForMap {
  background-color: #0000004d;
  border-radius: 3px;
  left: 8px;
  bottom: 7px;
  max-width: 150px;
  transition: all 0.5s ease;
  @apply flex-row flex items-center h-18px p-1 absolute space-x-1;
}

@media (min-width: 1280px) {
  .realEstate:hover {
    transform: scale(1.05);
    transition-duration: 0.5s;
    border-color: #2459ad;
    box-shadow: -1px 0 8px rgba(36, 89, 173, 0.73);
  }
  .realEstateId {
    transform: translateY(150%);
    opacity: 0;
  }
  .mediaCountContainer {
    transform: translateY(150%);
    opacity: 0;
  }
}

.txtRealEstateId {
  font-size: 10px;
  @apply truncate text-white;
}

.txtRealEstateIdForMap {
  font-size: 10px;
  @apply truncate text-white;
}

.codeIcon {
  @apply w-10px h-10px lg:w-16px lg:h-16px;
}

.codeIconForMap {
  @apply w-10px h-10px;
}

.iconCountMediaFile {
  @apply w-13px h-10px;
}

.iconCountMediaFileForMap {
  @apply w-9px h-7px;
}

.tagRealEstate {
  width: 37px;
  height: 19px;
  @apply absolute top-2;
}

.tagRealEstateForMap {
  width: 40px;
  height: 22px;
  @apply absolute top-2;
}

@media (min-width: 1024px) {
  .txtRealEstateId {
    font-size: 16px;
  }
  .textCountMedia {
    font-size: 11.41px;
  }
  .countMediaFile {
    width: 57.45px;
    height: 29.43px;
  }
  .tagRealEstate {
    width: 54px;
    height: 28px;
  }
}
