.headerContainer{
  @apply hidden space-x-4
}

@media (min-width: 1500px) {
  .headerContainer {
    @apply flex
  }
}

.showDropdown {
  @apply flex
}

@media (min-width: 1500px) {
  .showDropdown {
    @apply hidden
  }
}
