@tailwind base;
@tailwind components;
@tailwind utilities;

.gutter-bar {
  cursor: row-resize;
}
body {
  padding: 0 !important;
  margin: 0;
  color: #22313e;
  @apply font-text;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
}
::-webkit-scrollbar-thumb {
  border-radius: 9999px;
  border: 2px solid #e4e6ec;
  background-clip: padding-box;
  background-color: #0000004d;
}
::-webkit-scrollbar-track {
  background-color: #e4e6ec;
}
#root {
  width: 100%;
  //height: 100%;
}
.leaflet-container {
  //background: #f2f2f2;
  outline: 0;
}
.selectItem {
  padding-left: 8px;
  color: #80888f;
  @apply text-sm w-full items-center flex cursor-pointer py-1;
}

.selectItem:hover {
  background-color: #edf4ff;
  @apply text-blue-main font-bold;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 10000;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
.example-thumb {
  font-size: 11px;
  text-align: center;
  color: #222;
  cursor: pointer;
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #dedede;
  height: 4px;
}
.example-track.example-track-1 {
  background: #2459ad;
}
.example-track.example-track-2 {
  background: #dedede;
}
.horizontal-slider .example-track {
  height: 4px;
}

.horizontal-slider .example-thumb {
  top: -10px;
  width: 22px;
  height: 22px;
  background: #fff;
  border-width: 1px;
  border-color: #2459ad;
  border-style: solid;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
}
.itemTable {
  @apply h-50px flex flex-row text-sm border-r border-gray-300 px-2 items-center text-black-main;
}
.itemTable:last-child {
  @apply border-r-0;
}

.row {
  @apply flex-1 w-full flex flex-row;
}
.row:hover {
  background-color: #ffe6b450;
}

.tagFilterResult {
  border-radius: 15px;
  max-width: 100%;
  padding: 0 4px 0 4px;
  background-color: #f4f4f4;
  height: 23px;
  @apply w-auto flex flex-row items-center justify-between overflow-hidden space-x-1.5;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 900;
  pointer-events: none;
}

.require {
  color: red;
}

.form-center {
  margin: 0 auto;
  position: relative;
  width: 80%;
  margin-left: 10%;
}

.form-center .section {
  border: 1px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(97, 97, 97, 0.2);
  box-shadow: 0px 1px 2px 0px rgba(97, 97, 97, 0.2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  position: static;

  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(97, 97, 97, 0.2),
    0px 2px 4px rgba(97, 97, 97, 0.2);

  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 15px 0px;
}

.form-center .section .title {
  color: #2459ad;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 30px;
}

.bgf4 {
  background: #f4f4f4;
  border-radius: 8px;
}

.w15p {
  width: 15%;
}

.w30p {
  width: 30%;
}

.w35p {
  width: 35%;
}

.w70p {
  width: 70%;
}

.w85p {
  width: 85%;
}

.w100p {
  width: 100%;
  line-height: 35px;
}

.pr15x {
  padding-right: 15px;
}

.pr25x {
  padding-right: 25px;
}

.mb15x {
  margin-bottom: 15px;
}

.w08r {
  width: 0.8rem;
}

.munset {
  margin: unset !important;
}

.right {
  position: absolute;
  right: 0;
}

.r10p {
  right: 10%;
}

.font18x {
  font-size: 18px;
}

.font14x {
  font-size: 14px;
}

.dropdownSelectItem {
  @apply flex  h-44px px-2.5 text-sm cursor-pointer items-center;
}
.dropdownSelectItemRadio {
  @apply h-30px m-2.5 cursor-pointer;
}
.dropdownSelectItem:hover {
  background-color: #2459ad;
  color: white !important;
}
