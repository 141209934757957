.itemSelect{
  @apply flex items-center px-6 text-sm font-medium py-1
}

.itemSelect:hover{
  background-color: #EDF4FF;
}

.arrowIcon{
  @apply w-4 h-4
}

