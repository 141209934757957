.navigation {
  border-radius: 50%;
  z-index: 1;
  top: calc(50% - 30px);
  @apply absolute w-22px h-22px w-48px h-48px
}

.prev {
  transform: translate(-50%, -30%);
  left: 0;
}

.next {
  transform: translate(-50%, -40%);
  right: -45px;
}

.iconNext {
  transform: rotate(180deg);
}
