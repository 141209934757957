.itemTable {
  @apply h-40px flex flex-row text-sm border-r border-gray-300 px-2 items-center text-black-main;
}

.row {
  @apply flex flex-row;
}
.row:hover {
  background-color: #ffe6b450;
}

.tagFilterResult {
  border-radius: 15px;
  max-width: 100%;
  padding: 0 4px 0 4px;
  background-color: #f4f4f4;
  height: 23px;
  @apply w-auto flex flex-row items-center justify-between overflow-hidden space-x-1.5;
}
