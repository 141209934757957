.buttonContainer {
  min-height: 40px;
  @apply w-full flex flex-row justify-center items-center space-x-2 rounded-lg px-2 py-2;
}

.blueButton {
  @apply bg-blue-main;
}
.blueButton:hover {
  box-shadow: 1px 1px 12px -7px #000000;
}
.grayButton {
  border: 1px solid #80888f;
  background-color: #f4f4f4;
}
.grayButton:hover {
  box-shadow: 1px 1px 12px -7px #000000;
}

.whiteButton {
  boder: 1px solid #2459AD;
  background-color: #FFFFFF;
}
.whiteButton:hover {
  box-shadow: 0px 0px 5px #79ADFF;
}

.redButton {
  @apply bg-red-600;
}
.otherButton {
  border: 1px dashed #2459ad;
  @apply rounded-full;
}
.otherLabel {
  @apply text-sm text-blue-main font-medium;
}

.blueLabel {
  @apply text-sm text-white font-medium;
}

.grayLabel {
  color: #22313e;
  @apply text-sm font-medium;
}

.redLabel {
  @apply text-sm text-white font-medium;
}
